<notifier-container></notifier-container>
<section class="contacto">
    <iframe class="mapa"  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d686.406981875135!2d-71.34713878719337!3d-40.154531827569215!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x96110e7763b1444d%3A0x1a41118eedddb44!2sGral.%20Roca%201190%2C%20Q8370%20San%20Mart%C3%ADn%20de%20los%20Andes%2C%20Neuqu%C3%A9n%2C%20Argentina!5e0!3m2!1ses!2ses!4v1737637941656!5m2!1ses!2ses" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    
    <div class="marcoContacto">
        <div class="tituloContacto">
            <h3>CONTACTO</h3>
        </div>
        <div class="container divContacto">
            <div class="row gridContacto">
                <div class="col-md-6 col-sm-12 boxDireccion">
                <h4 class="direccion">Dirección:</h4>
                <p class="direccion">Gral Roca 1190, esquina Mascardi</p>
                <p class="direccion"><span>S M. de los Andes</span></p>
                <p class="direccion"><span>info@bexpatagonia.com.ar</span></p>
                </div>
                <div class="col-md-6 col-sm-12 boxHorarios">
                <h4 class="horarios">Horarios:</h4>
                <p class="horarios"><span>Lun - Vie:</span> 10 a 12:30 hs & 16 a 19 hs</p>
                <p class="horarios"><span>Sábados:</span> 10 a 12:30 hs</p>
                <p class="horarios"><span>Tel:</span> (02972) 412-644</p>
                </div>
            </div>
            <form [formGroup]="form" (ngSubmit)="submit()" class="formularioContacto">
                <input type="text" id="nombre" name="nombre" formControlName="name" placeholder="Nombre completo">
                <input [class.is-invalid]="form.get('mail_envio')?.invalid && form.get('mail_envio')?.touched" type="email" id="email" name="email" formControlName="mail_envio" placeholder="Tu e-mail">
                <textarea id="mensaje" name="mensaje" formControlName="msg" placeholder="Tu Mensaje"></textarea>
                <input class="boton" id="submit" name="submit" type="submit" value="Enviar">

            </form>
        </div>
    </div>
</section>