import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CpanelComponent } from './pages/cpanel/cpanel.component';
import { MainComponent } from './pages/main/main.component';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { LoginComponent } from './pages/login/login.component';
import { VerifyAccountComponent } from './pages/verify-account/verify-account.component';
import { SecureInnerPageGuard } from './shared/guard/secure-inner-page.guard';
import { AuthGuard } from './shared/guard/auth.guard';
import { MainMenuComponent } from './pages/main-menu/main-menu.component';


const routes: Routes = [
  { path: 'verify-account', component: VerifyAccountComponent, canActivate: [AuthGuard] },
  { path: '', component: MainComponent },
  // { path: '', component: LoginComponent, canActivate: [AuthGuard] },
  { path: 'carta/:id', component: MainMenuComponent },
  { path: 'login', component: LoginFormComponent, canActivate: [SecureInnerPageGuard] },
  { path: 'cpanel', component: CpanelComponent, canActivate: [SecureInnerPageGuard]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
